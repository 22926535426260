<template>
  <div>
    <v-toolbar
      v-if="!noTitle"
      flat
      color="primary"
      dark
    >
      <v-toolbar-title v-if="EMUC.Competition!==undefined">Eredményrögzítés: {{EMUC.Competition.name}}, {{EMUC.Competition.place}} - {{EMUC.Competition.date}}: {{EMUC.Exercise_MeasurementUnit.Exercise.name}} ({{EMUC.Exercise_MeasurementUnit.MeasurementUnit.unit}}) [{{EMUC.Age_Group.shortname}} - {{EMUC.Age_Group.name}}]</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-if="EMUC.Teams">
      <v-tab v-for="team in EMUC.Teams" :key="team.id">{{team.name}} ({{team.CompetitionTimetable.startTime}})</v-tab>

      <v-tab-item v-for="team in EMUC.Teams" :key="team.id">
        <resultLog
          :kidAthletes="team.KidAthletes"
          :id="EMUC.id"
          type="competitionExercise"
          :changeable="EMUC.Competition.status==1&&!coordinatorView"
          :criteria="EMUC.Exercise_MeasurementUnit.Criteria.filter(C => C.AgeGroupId==EMUC.Age_Group.id).map(C => {
            return {
              ...C,
              ExerciseMUId: EMUC.Exercise_MeasurementUnit.id
            }
            })"
          :results="EMUC.Results"
          :exercises="[{...EMUC.Exercise_MeasurementUnit.Exercise}]"
          :EMUs="[{id: EMUC.Exercise_MeasurementUnit.id, ExerciseId: EMUC.Exercise_MeasurementUnit.Exercise.id, MeasurementUnitId: EMUC.Exercise_MeasurementUnit.MeasurementUnit.id}]"
          :measurementUnits="[{...EMUC.Exercise_MeasurementUnit.MeasurementUnit}]"
          :enablePoints="false"
          :ageGroups="[{...EMUC.Age_Group}]"
          :disableNameSort="true"
        />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import resultLog from './resultLog'
export default {
  name: 'competition-resultLog',
  props: ['EMUCId', 'noTitle', 'coordinatorView'],
  components: {
    resultLog
  },
  data: function(){
    return {
      EMUC: [],
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    getEMUC: function() {
      this.axios({url: `competition/EMUCresults/${this.EMUCId}`, method: 'GET'}).then(response => {
        if(response.data.success) {
          this.EMUC=response.data.data.EMUC;
          this.EMUC.Teams = this.EMUC.Teams.sort((a,b) => {
            if(a.CompetitionTimetable.startTime<b.CompetitionTimetable.startTime) return -1;
            else if(a.CompetitionTimetable.startTime>b.CompetitionTimetable.startTime) return 1;
            else return 0;
            })
          this.EMUC.Teams.forEach(T => {
            T.KidAthletes.forEach(KA => {
              KA.fullname = `${KA.KidAthlete_Team.serialNumber}. ${KA.fullname}`
            })
            T.KidAthletes = T.KidAthletes.sort((a, b) =>{
              if(a.KidAthlete_Team.serialNumber<b.KidAthlete_Team.serialNumber) return -1;
              else if(a.KidAthlete_Team.serialNumber>b.KidAthlete_Team.serialNumber) return 1;
              else return 0;
            })
          })
        }
      })
    }
  },
  mounted() {
    this.getEMUC();
  }
}


</script>
