<template>
  <div>
    <v-data-table
      :items="kidAthletes"
      :headers="tableHeadersAndSlots.headers"
    >
      <template v-slot:top>
        <v-switch v-model="showPoints" v-if="enablePoints" label="Pontok mutatása"></v-switch>
      </template>
      <template v-for="slot in tableHeadersAndSlots.slots"  v-slot:[slot.slot]="{item}">
        <span v-if="item.ageByYears<=slot.maxAge">
          <v-text-field
            v-if="!showPoints"
            v-for="attempt in slot.attempts"
            :key="attempt"
            :value="getResult(item.id,slot.slot,slot.time, attempt)"
            :label="`${attempt+1}. kísérlet`"
            rounded
            :readonly="!changeable"
            outlined
            :rules="slot.time?[fieldRules.isTime]:[fieldRules.isNumber]"
            @change="changeResult(item.id,slot.slot.substring(5),attempt,$event, slot.time)"
          />
          <span v-else>
            {{getPoint(item.id,slot.slot,attempt)}}
          </span>
        </span>
        <span v-else>
          A kölyökatléta kora meghaladja a tesztgyakorlat célkorcsoportjának maximális korát
        </span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: [
    'kidAthletes',
    'id',
    'type',
    'changeable',
    'criteria',
    'results',
    'exercises',
    'EMUs',
    'measurementUnits',
    'enablePoints',
    'ageGroups',
    'disableNameSort'
  ],
  data () {
    return {
      showPoints: false,
      dialogs: {

      }
    }
  },
  computed: {
    tableHeadersAndSlots() {
      var h = [{
        text: 'Név',
        align: 'center',
        sortable: !this.disableNameSort,
        filterable: false,
        groupable: false,
        value: 'fullname'
      }]
      var s = []
      if(this.criteria) this.criteria.forEach(C => {
        const emu = this.EMUs.find(EMU => EMU.id==C.ExerciseMUId)
        const e = this.exercises.find(E => E.id==emu.ExerciseId)
        const mu = this.measurementUnits.find(mU => mU.id==emu.MeasurementUnitId)
        h.push(
          {
            text: `${e.name} (${mu.unit}) - ${e.Exercise_Categories.map(c => c.name).join(', ')}`,
            align: 'center',
            sortable: false,
            filterable: false,
            groupable: false,
            value: `${C.ExerciseMUId}-${C.AgeGroupId}`
          }
        )
        const ageGroup = this.ageGroups.find(aG => aG.id==C.AgeGroupId);
        var attempts = [];
        for(var i = 0;i<C.attempts;i++) attempts.push(i)
        s.push({slot: `item.${C.ExerciseMUId}-${C.AgeGroupId}`, time: mu.id==-1, attempts: attempts, maxAge: ageGroup? ageGroup.maxAge:0})
      })

      return {headers: h, slots: s}
    }
  },
  methods: {
    getPoint: function(kidAthleteId, slot, attempt) {
      const splittedSlot= slot.substring(5).split('-')
      var r = this.results.find(R => R.KidAthleteId==kidAthleteId&&R.AgeGroupId==splittedSlot[1]&&R.ExerciseMUId==splittedSlot[0])
      const c = this.criteria.find(C => C.AgeGroupId==splittedSlot[1]&&C.ExerciseMUId==splittedSlot[0])
      return r ? (r.result?`${this.getPointFromResultAndCriteria(r.result, c.ascendingTendent, c.criteria)}`: 'Nem teljesített') : 'Nem teljeített'
    },
    getResult: function(kidAthleteId, slot, time, attempt){
      const splittedSlot= slot.substring(5).split('-')
      var r = this.results.find(R => R.KidAthleteId==kidAthleteId&&R.AgeGroupId==splittedSlot[1]&&R.ExerciseMUId==splittedSlot[0])
      return r ? (r.result?(time ? this.time(r.result[attempt]) : r.result[attempt]):''):''
    },
    changeResult: function(kidAthleteId,slot,attempt, event, time) {
      const splittedSlot=slot.split("-");
      var r = this.results.find(R => R.KidAthleteId==kidAthleteId&&R.AgeGroupId==splittedSlot[1]&&R.ExerciseMUId==splittedSlot[0])
      var input = event.replace(',','.');
      if(time? this.fieldRules.isTime(input)===true:this.fieldRules.isNumber(input)===true) {
        if(r) {
          const queryData = {
            result: {
              AgeGroupId: r.AgeGroupId,
              KidAthleteId: r.KidAthleteId,
              ExerciseMUId: r.ExerciseMUId,
              createdAt: r.createdAt,
              changedAttempt: attempt,
              changedResult: time? this.timeInputToMs(input) : input
            }
          }
          this.axios({url: `/result/${this.type}/${this.id}`, method: 'PUT', data: queryData}).then(response => {
            if(response.data.success) {
              r.result[attempt] = parseFloat(queryData.result.changedResult);
              this.$store.commit('setSnack','Mentve.')

            }
          })
        }else {
          var newResult = {
            AgeGroupId: splittedSlot[1],
            ExerciseMUId: splittedSlot[0],
            KidAthleteId: kidAthleteId,
            result: []
          }
          newResult.result[attempt]=parseFloat(time? this.timeInputToMs(input) : input);
          this.axios({url: `/result/${this.type}/${this.id}`, method: 'POST', data: {result: newResult}}).then(response => {
            if(response.data.success) {
              this.results.push({...newResult, createdAt: response.data.data.createdAt})
              this.$store.commit('setSnack','Mentve.')
            }
          })
        }
      }
      else {
        this.$store.commit('setSnack', 'Nem megfelelő értéket adott meg!')
      }

    },
    helpTimeInput: function(kidAthleteId,slot, attempt,event) {
      const splittedSlot=slot.split("-");
      var r = this.results.find(R => R.KidAthleteId==kidAthleteId&&R.AgeGroupId==splittedSlot[1]&&R.ExerciseMUId==splittedSlot[0])
      var input = event;


      input = input.replace(/[^0-9:.]/g,"");
      if(input.length==2||input.length==5) input+=":"
      if(input.length==8) input+="."
      input = input.replace(/(\b::\b)/,":")
      console.log(input)
      console.log(r.result)
      r.result[attempt]=input;
    }
  },
  mounted() {
  }
}
</script>
